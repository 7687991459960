<template>
  <b-form-group id="input-group-2" role="group" :label="$t('PASSWORD')" label-for="input-2">
    <b-input-group class="mb-2" for="input-live">
      <b-input-group-prepend is-text>
        <b-icon icon="lock-fill" />
      </b-input-group-prepend>
      <b-form-input
        id="input-live"
        v-model="password"
        type="password"
        name="password"
        :description="$t('PASSWORD_RULE')"
        :state="passwordState()"
        required
        aria-describedby="input-live-feedback"
        trim
        :placeholder="placeholder()"
        @doLogin.stop.prevent
        @input="$emit('setPassword', $event)"
      />
    </b-input-group>
    <b-form-invalid-feedback ref="invalid-fb" :state="passwordState()" class="text-dark"> {{ $t('PASSWORD_RULE') }} </b-form-invalid-feedback>
    <b-form-valid-feedback :state="passwordState()"> {{ $t('PASSWORD_RULE_OK') }} </b-form-valid-feedback>
  </b-form-group>
</template>

<script>
export default {
  name: 'Password',
  props: {
    newUser: Boolean,
    connectionType: String,
  },
  data: () => ({ password: '' }),
  methods: {
    passwordState() {
      return this.password.length >= 2 ? true : undefined;
    },
    placeholder() {
      switch (this.connectionType) {
        case 'register':
          return this.$t('PASSWORD_PLACEHOLDER_PICK');
        case 'login':
          return this.$t('PASSWORD_PLACEHOLDER_ENTER');
        case 'update':
          return this.$t('CONFIRM_PASSWORD');
        default:
          return '';
      }
    },
  },
};
</script>
