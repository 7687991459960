import fetch from 'node-fetch';
import { C } from '@/constants/constants';

async function handleResponse(response) {
  const r = await response.json();
  if (response.ok) return r;
  return Promise.reject(r);
}

function start(symbol, language) {
  const payload = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ symbol }),
  };
  return fetch(`${C.SERVER_URL}/game/start/${language}`, payload)
    .then(handleResponse);
}

function addDefinition(data) {
  const payload = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${C.SERVER_URL}/game/definition/add`, payload)
    .then(handleResponse);
}

function removeDefinition(data) {
  const payload = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${C.SERVER_URL}/game/definition/delete`, payload)
    .then(handleResponse);
}

function addStopSymbol(symbol, game) {
  const payload = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ symbol, game }),
  };
  return fetch(`${C.SERVER_URL}/game/add-stop-symbol`, payload)
    .then(handleResponse);
}

function removeStopSymbol(symbol, game) {
  const payload = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ symbol, game }),
  };
  return fetch(`${C.SERVER_URL}/game/remove-stop-symbol`, payload)
    .then(handleResponse);
}

function save(data) {
  const payload = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${C.SERVER_URL}/game/save`, payload)
    .then(handleResponse);
}

function loadMany(username) {
  const payload = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(username),
  };
  return fetch(`${C.SERVER_URL}/game/load`, payload)
    .then(handleResponse);
}

function load(data) {
  const payload = {
    method: 'GET',
  };
  return fetch(`${C.SERVER_URL}/game/load/${data.id}/${data.language}`, payload)
    .then(handleResponse);
}

function update(data) {
  const payload = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${C.SERVER_URL}/game/update`, payload)
    .then(handleResponse);
}

function remove(data) {
  const payload = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username: data.username }),
  };
  return fetch(`${C.SERVER_URL}/game/delete/${data.id}/${data.language}`, payload)
    .then(handleResponse);
}

const gameServices = {
  addDefinition,
  removeDefinition,
  addStopSymbol,
  start,
  removeStopSymbol,
  save,
  update,
  remove,
  load,
  loadMany,
};

export default gameServices;
