<template>
  <div>
    <b-form @submit="onSubmit">
      <b-row class="mt-2">
        <b-col>
          <ToDefine :toDefine="game.state.to_define" />
        </b-col>
        <b-col>
          <Definition :symbol="game.state.symbol" :definition="game.state.definition" />
        </b-col>
      </b-row>
    </b-form>
    <b-row class="mt-2">
      <b-col>
        <GameButtons />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <GameStatus />
      </b-col>
      <b-col>
        <DictionaryView />
      </b-col>
    </b-row>
<!--    <b-row class="d-flex justify-content-center flex-nowrap mt-5">-->
<!--      <div id="game-svg" v-html="game.state.svg" />-->
<!--    </b-row>-->
<!--    <Temp />-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ToDefine from '@/components/game/ToDefine.vue';
import Definition from '@/components/game/Definition.vue';
import GameStatus from '@/components/game/Status.vue';
import GameButtons from '@/components/game/Buttons.vue';
import Temp from '@/components/game/Temp.vue';
import DictionaryView from '@/components/game/View.vue';

export default {
  name: 'Play',
  components: {
    ToDefine,
    Definition,
    GameStatus,
    GameButtons,
    Temp,
    DictionaryView,
  },
  data() {
    return {
      stopSymbol: '',
      startSymbol: '',
    };
  },
  computed: {
    ...mapState(['game']),
    ...mapState('user', ['user', 'message', 'status', 'callback']),
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  },
};
</script>
