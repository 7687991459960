<template>
  <div v-if="user" :key="rendering" class="container center">
    <template id="login">
      <b-container class="bv-example-row" fluid="md">
        <b-col class="mx-auto app-login-box">
          <div class="modal-content">
            <div class="modal-body">
              <b-form @submit="onSubmit">
                <div class="modal-header justify-content-center">
                  <h4 class="text-info modal-title"><b>{{ user.username }} </b>{{ $t('UPDATE_ACCOUNT') }} </h4>
                </div>
                <b-row class="mt-2">
                  <b-col>
                    <Email :email="user.email" @setEmail="setProp('email', $event)" />
                    <Age :age="user.age" @setAge="setProp('age', $event)" />
                    <Gender :gender="user.gender" @setGender="setProp('gender', $event)" />
                    <EducationLevel :education_level="user.education_level" @setEducationLevel="setProp('education_level', $event)" />
                    <CountryRegion :country="user.country" :region="user.region" @setCountry="setProp('country', $event)" @setRegion="setProp('region', $event)" />
                  </b-col>
                  <b-col>
                    <Languages :languages="user.languages" @setLanguages="setProp('languages', $event)" />
                  </b-col>
                </b-row>
                <div class="modal-footer clearfix">
                  <b-col class="mt-2" />
                  <b-col class="mt-2">
                    <Password :newUser="true" :connectionType="'update'" @setPassword="setProp('password', $event)" />
                    <div class="text-center">
                      <router-link to="/user/profile"><b-button class="mr-1" variant="secondary"> {{ $t('CANCEL') }} </b-button></router-link>
                      <b-button class="mr-1" variant="info" type="submit" @click="doUpdate"> {{ $t('UPDATE') }} </b-button>
                      <b-button class="mr-1" variant="danger" type="submit" @click="doDelete"> {{ $t('DELETE') }} </b-button>
                    </div>
                  </b-col>
                </div>
              </b-form>
              <div class="divider" />
            </div>
          </div>
        </b-col>
      </b-container>
      <Modal v-if="message && status === 'updated'" :src="require('../../../public/images/board-4874863_960_720.jpg')" :message="message" buttonText="Ok" :callback="callback" />
      <Modal v-if="message && status === 'failure'" :src="require('../../../public/images/board-4874863_960_720.jpg')" :message="message" buttonText="ok" :callback="callback" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Password from './profile/Password.vue';
import Email from './profile/Email.vue';
import Age from './profile/Age.vue';
import EducationLevel from './profile/EducationLevel.vue';
import Gender from './profile/Gender.vue';
// import Username from './profile/Username.vue';
import CountryRegion from './profile/CountryRegion.vue';
import Languages from './profile/Languages.vue';
import Modal from '../Modal.vue';

export default {
  name: 'Update',
  components: {
    Password,
    Email,
    Age,
    EducationLevel,
    Gender,
    CountryRegion,
    Languages,
    Modal,
  },
  data() {
    return {
      form: {},
      rendering: 0,
    };
  },
  computed: {
    ...mapState('user', ['user', 'message', 'status', 'callback']),
  },
  mounted() {
    this.form = { ...this.user, password: '' };
  },
  methods: {
    ...mapActions('user', { update: 'update', remove: 'remove' }),
    validate() {
      const { username, password, email, age, languages, country, region, education_level, gender } = this.form;
      const validateLanguage = Object.entries(languages).every(([k, v]) => (v.code && v.knowledge_language && v.knowledge_age));
      return !!(username && password && password.length > 1 && email && validateLanguage && age && languages && country && region && education_level && gender);
    },
    doUpdate() {
      if (this.validate()) {
        const { username, password, email, age, languages, country, region, education_level, gender } = this.form;
        const language = this.$i18n.locale;
        const data = { username, password, email, age, languages, country, region, education_level, gender, language };
        this.update({ data });
        if (this.status !== 'updated') this.rendering++;
      }
    },
    doDelete() {
      if (this.validate()) {
        const { username, password, email, age, languages, country, region, education_level, gender } = this.form;
        const language = this.$i18n.locale;
        const data = { username, password, email, age, languages, country, region, education_level, gender, language };
        this.remove({ data });
        if (this.status !== 'deleted') this.rendering++;
      }
    },
    onSubmit(event) {
      event.preventDefault();
    },
    setProp(propname, value) {
      this.form[propname] = value;
    },
  },
};
</script>
