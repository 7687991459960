<template>
  <b-container class="bv-example-row" fluid="md">
    <b-row class="justify-content-center">
      <b-alert show class="mt-5">
        <b-link class="text-primary mt-5" :to="{ path: '/user/login'}">
          <span>{{ $t('ALREADY_HAVE_ACCOUNT') }}</span>
          <b-icon class="float" icon="person-fill" />
          <span>{{ $t('LOGIN') }}</span>
        </b-link>
      </b-alert>
    </b-row>
    <b-row class="justify-content-center">
      <b-alert show class="mt-5" variant="success">
        <b-link class="text-success" :to="{ path: '/user/register'}">
          <span>{{ $t('DONT_HAVE_ACCOUNT_YET') }}</span>
          <b-icon class="float" icon="person-plus-fill" />
          <span>{{ $t('YOU_CAN_REGISTER') }}</span>
        </b-link>
      </b-alert>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Connect',
};
</script>
