<template>
  <b-card class="mt-2 shadow bg-white">
    <label for="tags-basic">{{ $t('TO_DEFINED_PLACEHOLDER') }}: </label>
    <b-form-select :select-size="5" :options="toDefine" class="mt-2 mb-3" options-field="test" required @change="onChange($event)" />
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ToDefine',
  props: {
    toDefine: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapActions('game', { setSymbol: 'setSymbol', setDefinition: 'setDefinition' }),
    onChange(symbol) {
      this.setSymbol({ symbol });
      this.setDefinition({ definition: '' });
    },
  },
};
</script>
