<template>
  <b-form-group id="input-group-1" :label="$t('USERNAME')" label-for="input-1">
    <b-input-group class="mb-2">
      <b-input-group-prepend is-text>
        <b-icon icon="person-fill" />
      </b-input-group-prepend>
      <b-form-input
        id="input-username"
        :value="username"
        type="text"
        name="username"
        required
        :formatter="formatter"
        :placeholder="placeholder()"
        @input="$emit('setUsername', $event)"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>

export default {
  name: 'Username',
  props: {
    username: String,
    newUser: Boolean,
  },
  methods: {
    formatter(value) {
      return value.toLowerCase();
    },
    placeholder() {
      if (this.newUser) {
        return this.$t('USERNAME_PLACEHOLDER_PICK');
      }
      return this.$t('USERNAME_PLACEHOLDER_ENTER');
    },
  },
};
</script>
