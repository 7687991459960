<template>
  <div class="mt-5 mb-5 addInformation">
    <b-button v-b-toggle.collapse-1 variant="warning">{{ $t('ADDITIONAL_INFORMATION') }}</b-button>
    <b-collapse id="collapse-1" class="mt-2">
      <b-card>
        <div>
          {{ $t('STOPS_SYMBOLS') }}:
          <label>
            <input v-model="stopSymbol" placeholder="enter stop symbol">
          </label>
          <button @click="doAddStopSymbol">{{ $t('ADD') }}</button>
          <button @click="doRemoveStopSymbol">{{ $t('REMOVE') }}</button>
        </div>
        <h2>{{ $t('STOPS_SYMBOLS') }}</h2>
        <p>{{ game.stop_symbols.length }} entry(ies):</p>
        <ul id="stop-symbols">
          <li v-for="symbol in game.stop_symbols" :key="symbol">
            {{ symbol }}
          </li>
        </ul>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Temp',
  data() {
    return {
      minWords: 100,
      stopSymbol: '',
    };
  },
  computed: {
    ...mapState(['game']),
  },
  methods: {
    ...mapActions('game', {
      addStopSymbol: 'addStopSymbol',
      removeStopSymbol: 'removeStopSymbol',
    }),
    doAddStopSymbol(event) {
      if (event) {
        const { stopSymbol, game } = this;
        this.addStopSymbol({ stopSymbol, game });
        this.stopSymbol = '';
      }
    },
    doRemoveStopSymbol(event) {
      if (event) {
        const { stopSymbol, game } = this;
        this.removeStopSymbol({ stopSymbol, game });
        this.stopSymbol = '';
      }
    },
  },
};
</script>
