import { router } from '@/router';
import userServices from './services/user.services';
import datosLanguages from '../constants/languages.json';
import countryData from '../constants/countryData.json';

const state = {
  status: undefined,
  user: undefined,
  message: undefined,
  callback: undefined,
  games: [],
};

const getters = {
  itemsLanguage(state) {
    return state.user.languages.map((obj) => {
      const index = datosLanguages.findIndex((item) => item.value === obj.code);
      return { ...obj, language: datosLanguages[index].text };
    });
  },
  countryIdx(state) {
    if (!state.user) return -1;
    return countryData.findIndex((item) => item.countryShortCode === state.user.country);
  },
  countryString(state) {
    const idx = getters.countryIdx(state);
    if (idx === -1) return '';
    return countryData[idx].countryName;
  },
  regionString(state) {
    if (!state.user) return '';
    const countryIdx = getters.countryIdx(state);
    if (countryIdx === -1) return '';
    const indexRegion = countryData[countryIdx].regions.findIndex((item2) => item2.shortCode === state.user.region);
    if (indexRegion === -1) return countryData[countryIdx].regions[0].name;
    return countryData[countryIdx].regions[indexRegion].name;
  },
};

const actions = {
  register({ commit }, { data }) {
    commit('inRequest');
    userServices.register(data)
      .then(({ user, message }) => {
        const callback = () => { router.push('/game'); commit('closeModal'); };
        commit('registerSuccess', { user });
        commit('openModal', { message, callback });
      })
      .catch((err) => {
        const callback = () => { commit('reset'); commit('closeModal'); };
        commit('reqFailure');
        commit('openModal', { message: err.message, callback });
      });
  },
  update({ commit }, { data }) {
    commit('inRequest');
    userServices.update(data)
      .then(({ user, message }) => {
        const callback = () => { router.push('/user/profile'); commit('closeModal'); };
        commit('updateSuccess', { user });
        commit('openModal', { message, callback });
      })
      .catch((err) => {
        const callback = () => commit('closeModal');
        commit('reqFailure');
        commit('openModal', { message: err.message, callback });
      });
  },
  remove({ commit }, { data }) {
    commit('inRequest');
    userServices.remove(data)
      .then(({ message }) => {
        const callback = () => { router.push('/'); commit('closeModal'); };
        commit('deleteSuccess');
        commit('openModal', { message, callback });
      })
      .catch((err) => {
        const callback = () => commit('closeModal');
        commit('reqFailure');
        commit('openModal', { message: err.message, callback });
      });
  },
  login({ commit }, { data }) {
    commit('inRequest');
    userServices.login(data)
      .then(({ user, message }) => {
        const callback = () => { router.push('/game'); commit('closeModal'); };
        commit('loginSuccess', { user });
        commit('openModal', { message, callback });
      })
      .catch((err) => {
        const callback = () => commit('reset');
        commit('reqFailure');
        commit('openModal', { message: err.message, callback });
      });
  },
  logout({ commit }) {
    commit('reset');
    router.push('/');
  },
  reset({ commit }) {
    commit('reset');
  },
  openModal({ commit }, { message, callback }) {
    commit('openModal', { message, callback });
  },
  closeModal({ commit }) {
    commit('closeModal');
  },
};

const mutations = {
  reset(state) {
    state.status = undefined;
    state.user = undefined;
    state.message = undefined;
  },
  setStatus(state, { status }) {
    state.status = status;
  },
  inRequest(state) {
    state.status = 'request';
  },
  reqFailure(state) {
    state.status = 'failure';
  },
  registerSuccess(state, { user }) {
    state.status = 'registered';
    state.user = user;
  },
  updateSuccess(state, { user }) {
    state.status = 'updated';
    state.user = user;
  },
  loginSuccess(state, { user }) {
    state.status = 'logged';
    state.user = user;
  },
  deleteSuccess(state) {
    state.status = 'deleted';
    state.user = undefined;
  },
  closeModal(state) {
    state.message = undefined;
    state.callback = undefined;
  },
  openModal(state, { message, callback }) {
    state.message = message;
    state.callback = callback;
  },
  loadGames(state, { games }) {
    state.games = games;
  },
};

export const user = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
