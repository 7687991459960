<template>
  <div class="w-100 mx-auto d-flex align-items-center clearfix justify-content-center">
    <b-card :title="$t('START_WORD_PLACEHOLDER')"
            border-variant="info"
            :img-src="require('../../../public/images/Image_dictionary.jpg')"
            img-alt="Image_dictionary"
            img-height="100"
            align="items-center"
            img-top
            style="max-width: 30rem"
            class="shadow w-40 text-center md-5"
    >
      <div>
        <b-button-group>
          <b-button v-for="(btn, idx) in startSymbols" :key="idx" variant="info" size="lg" @click="setStartSymbol(btn.caption)">
            {{ btn.caption }}
          </b-button>
        </b-button-group>
        <div class="modal-footer-center">
          <b-button type="submit" class="mr-1 mt-2 " variant="info" @click="doStart({random:false})">{{ $t('START') }}</b-button>
          <b-button id="button-random" class="mr-1 mt-2 " variant="secondary" @click="doStart({random:true})">{{ $t('RANDOM') }}</b-button>
          <b-tooltip target="button-random" :title="$t('RANDOM_PLACEHOLDER')" />
          <b-button type="submit" class="mr-1 mt-2 " variant="info" @click="doLoad">{{ $t('LOAD') }}</b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { C } from '@/constants/constants';

export default {
  name: 'Start',
  data: () => ({ startSymbol: '' }),
  computed: {
    ...mapState('user', ['user', 'games', 'message', 'status', 'callback']),
    startSymbols() {
      const language = this.$i18n.locale;
      return C.START_SYMBOLS[language].map((symbol) => Object.create({ caption: symbol, state: false }));
    },
  },
  methods: {
    ...mapActions('game', { start: 'start', loadMany: 'loadMany' }),
    ...mapActions('user', { openModal: 'openModal', closeModal: 'closeModal' }),
    setStartSymbol(startSymbol) {
      this.startSymbol = startSymbol;
    },
    doStart(options) {
      const { random } = options;
      const { startSymbol } = this;
      const language = this.$i18n.locale;
      if (random) {
        const [randomSymbol] = C.START_SYMBOLS[language].sort(() => Math.random() - 0.5);
        this.start({ startSymbol: randomSymbol, language });
      } else if (startSymbol) {
        this.start({ startSymbol, language });
      } else {
        const message = { subject: this.$t('GAME_START_SUBJECT_ERR'), body: this.$t('GAME_START_BODY_ERR'), variant: 'warning' };
        this.openModal({ message, callback: this.closeModal });
      }
    },
    doLoad() {
      if (!this.user) {
        const message = { subject: this.$t('GAME_LOAD_SUBJECT_ERR'), body: this.$t('GAME_LOAD_BODY_ERR'), variant: 'warning' };
        this.openModal({ message, callback: this.closeModal });
      } else {
        this.loadMany({ username: this.user.username });
        this.$emit('setLoad', true);
      }
    },
  },
};
</script>
