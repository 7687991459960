<template>
  <div class="container center">
    <template id="profile">
      <Connect v-if="!user" />
      <b-container v-if="user" class="bv-example-row" fluid="md">
        <b-row class="justify-content-end">
          <PrivacyPolicy :checkedValue="form.checked" @checkedInput="(newChecked)=>{form.checked = newChecked}" />
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-card class="mt-2 shadow bg-white">
              <b-avatar badge />
              <b>  {{ $t('PERSONAL_INFORMATION') }}</b>
              <p class="mt-2"><b>{{ $t('EMAIL')}}: </b>{{ user.email }}</p>
              <p><b>{{ $t('AGE') }}: </b>{{ user.age }}</p>
              <p><b>{{ $t('COUNTRY') }}: </b>{{ countryString }}</p>
              <p><b>{{ $t('REGION') }}: </b>{{ regionString }}</p>
              <p><b>{{ $t('GENDER') }}: </b>{{ user.gender }}</p>
              <p><b>{{ $t('EDUCATION_LEVEL') }}: </b>{{ user.education_level }}</p>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="mt-2 shadow bg-white">
              <b-avatar icon="exclamation-circle" />
              <b>  {{ $t('ACCOUNT_SECURITY') }}</b>
              <p class="mt-2"><b>{{ $t('USERNAME') }}: </b>{{ user.username }}</p>
              <p><b>{{ $t('PASSWORD') }}: </b><b type="password">********</b></p>
            </b-card>
            <router-link to="/user/update"><b-button class="mt-5" variant="info"> {{ $t('UPDATE_INFORMATIONS') }} </b-button></router-link>
          </b-col>
        </b-row>
        <b-card class="mt-2 shadow bg-white">
          <b-table striped hover :items="itemsLanguage" :fields="fields" />
        </b-card>
        <b-card class="mt-2 mb-5 shadow bg-white">
          <p><b>{{ $t('RATE_THE_GAME') }}</b></p>
          <b-form-rating v-model="rating" variant="success" class="mb-2 border border-success" />
        </b-card>
      </b-container>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PrivacyPolicy from './profile/PrivacyPolicy.vue';
import Connect from './Connect.vue';

export default {
  name: 'Profile',
  components: {
    Connect,
    PrivacyPolicy,
  },
  data() {
    return {
      form: {
        value: 0,
        checked: true,
      },
      fields: ['language', 'knowledge_language', 'knowledge_age'],
      modal: false,
      rating: undefined,
    };
  },
  computed: {
    ...mapState('user', ['user', 'status']),
    ...mapGetters('user', ['regionString', 'countryString', 'itemsLanguage']),
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  },
};
</script>

<style>
.action {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
