<template>
  <div v-if="game.state.entries.length" class="mt-3">
    <b-card class="mt-2 shadow bg-white">
      <div class="overflow-auto">
        <b-row class="mt-2">
          <b-col>
            <h3 class="text-info"><strong>{{ $t('DICTIONARY') }} </strong></h3>
          </b-col>
          <b-col>
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" :number-of-pages="items" aria-controls="my-table" align="center" />
          </b-col>
        </b-row>
        <b-table id="my-table" :items="items" :per-page="perPage" :current-page="currentPage" :fields="fields" small>
          <template #head(symbol)="data">
            <span class="text-info">{{ data.label.toUpperCase() }}</span>
          </template>
          <template #head(definition)="data">
            <span class="text-info">{{ data.label.toUpperCase() }}</span>
          </template>
          <template #head(gpt_rank)="data">
            <span class="text-info">{{ data.label.toUpperCase() }}</span>
          </template>
          <template #head(entry_id)>
            <span />
          </template>
          <template #cell(symbol)="data">
            <b class="font-italic">{{ data.value }} </b>
          </template>
          <template #cell(definition)="data">
            <span v-html="data.value"/>
          </template>
          <template #cell(gpt)="data">
            <span>{{ data.value }}</span>
          </template>
          <template #cell(entry_id)="data">
            <b-button class="btn-sm" variant="warning" @click="doRemoveDefinition(data.value)">{{ $t('DELETE') }}</b-button>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'DictionaryView',
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      minWords: 2,
      // fields: [{ symbol: this.$t('WORD') }, { definition: this.$t('DEFINITION') }, { gpt_rank: this.$t('GPT_RANK') }, { entry_id: this.$t('DELETE') }],
      fields: [{ symbol: this.$t('WORD') }, { definition: this.$t('DEFINITION') }, { entry_id: this.$t('DELETE') }],
    };
  },
  computed: {
    ...mapState(['game']),
    rows() {
      return this.game.state.entries.length;
    },
    items() {
      return this.game.state.entries.map((e) => {
        const { symbol, raw_definition, id } = e;
        const blurStopSymbols = (w) => {
          if (this.game.stop_symbols.includes(w)) {
            return `<span class="blur-shadow">${w}&nbsp</span>`;
          }
          return w;
        };
        const raw_definition_html = raw_definition.split(' ').map(blurStopSymbols).join(' ');
        // let { gpt_rank } = e;
        // gpt_rank = (this.$i18n.locale === 'fr') ? '-' : gpt_rank + 1;
        return { symbol, definition: raw_definition_html, entry_id: id };
      }).reverse();
    },
  },
  methods: {
    ...mapActions('game', { removeDefinition: 'removeDefinition' }),
    doRemoveDefinition(entry_id) {
      const data = { entry_id, game: this.game };
      this.removeDefinition({ data });
    },
  },
};
</script>

<style>
.blur-shadow{
  text-shadow:2px 2px 5px #101010;
  color:#666666;
}
</style>
