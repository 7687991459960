<template>
  <b-form-group id="input-group-8" :label="$t('EDUCATION_LEVEL')" label-for="input-8">
    <b-input-group class="mb-2">
      <b-form-select
        id="input-education"
        :value="education_level"
        list="input-list"
        type="String"
        name="education_level"
        :options="options"
        required
        :placeholder="$t('EDUCATION_LEVEL_PLACEHOLDER')"
        @change="$emit('setEducationLevel', $event)"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'EducationLevel',
  props: {
    education_level: String,
  },
  data() {
    return {
      options: [
        { value: '', text: this.$t('EDUCATION_LEVEL_PLACEHOLDER') },
        { value: 'Primary', text: this.$t('PRIMARY') },
        { value: 'Secondary', text: this.$t('SECONDARY') },
        { value: 'College', text: this.$t('COLLEGE') },
        { value: 'University', text: this.$t('UNIVERSITY') },
      ],
    };
  },
};
</script>
