<template>
  <b-form-group id="input-group-8" :label="$t('GENDER')" label-for="input-8">
    <b-input-group class="mb-2">
      <b-form-select
        id="input-gender"
        :value="gender"
        list="input-list"
        type="String"
        name="gender"
        :options="options"
        required
        :placeholder="$t('GENDER_PLACEHOLDER')"
        @change="$emit('setGender', $event)"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Gender',
  props: {
    gender: String,
  },
  data() {
    return {
      options: [
        { value: '', text: this.$t('GENDER_PLACEHOLDER') },
        { value: 'Woman', text: this.$t('WOMAN') },
        { value: 'Man', text: this.$t('MAN') },
        { value: 'Transgender', text: this.$t('TRANSGENDER') },
        { value: 'Non-binary/non-conforming', text: this.$t('NON_BINARY') },
        { value: 'Prefer not to respond', text: this.$t('PREFER_NOT_TO_SAY') },
      ],
    };
  },
  computed: {
    ...mapState('user', ['user', 'message', 'status', 'callback']),
  },
};
</script>
