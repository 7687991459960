<template>
  <b-form-group id="input-group-7" :label="$t('COUNTRY')" label-for="input-7">
    <b-input-group class="mb-2">
      <country-select
        id="Country"
        :country="country"
        :title="$t('COUNTRY_PLACEHOLDER')"
        type="select"
        topCountry="CA"
        required
        variant="outline-secondary"
        class="w-100 mb-2 text-secondary"
        @input="$emit('setCountry', $event)"
      />
      <b-tooltip target="Country" :title="$t('COUNTRY_PLACEHOLDER')" />
      <span>{{ $t('REGION') }}: </span>
      <region-select
        :country="country"
        :region="region"
        required
        class="mt-2 w-100 text-secondary"
        @input="$emit('setRegion', $event)"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'CountryRegion',
  props: {
    country: String,
    region: [String, Number],
  },
};
</script>
