<template>
  <div>
    <b-card class="shadow bg-white">
      <div>
        <b-alert show variant="info" align="center" class="mb-0">
          <b-button class="mr-2" variant="outline-info" @click="doRestart">{{ $t('RESTART') }}</b-button>
          <b-button class="mr-2" variant="outline-secondary" @click="doUndo">{{ $t('UNDO') }}</b-button>
          <b-button class="mr-2" variant="outline-secondary" @click="doRedo">{{ $t('REDO') }}</b-button>
<!--          <b-button class="mr-2" variant="outline-info" @click="doShuffle">{{ $t('SHUFFLE') }}</b-button>-->
          <b-button class="mr-2" variant="secondary" @click="doSave">{{ $t('SAVE') }}</b-button>
          <b-button class="mr-2" variant="secondary" @click="doRemove">{{ $t('DELETE') }}</b-button>
        </b-alert>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'GameButtons',
  computed: {
    ...mapState('user', ['user', 'message', 'status', 'callback']),
    ...mapState(['game']),
  },
  methods: {
    ...mapActions('game', {
      undo: 'undo',
      redo: 'redo',
      shuffle: 'shuffle',
      reset: 'reset',
      save: 'save',
      remove: 'remove',
    }),
    ...mapActions('user', { openModal: 'openModal', closeModal: 'closeModal' }),
    doUndo(event) {
      if (event) {
        const { game } = this;
        this.undo({ game });
      }
    },
    doRedo(event) {
      if (event) {
        const { game } = this;
        this.redo({ game });
      }
    },
    doShuffle(event) {
      if (event) {
        const { game } = this;
        this.shuffle({ game });
      }
    },
    doRestart() {
      this.reset();
    },
    doRemove() {
      if (!this.game._id) {
        this.reset();
      } else {
        const data = { username: this.user.username, id: this.game._id, language: this.$i18n.locale }
        this.remove({ data });
      }
    },
    doSave() {
      if (!this.user) {
        const message = { subject: this.$t('GAME_SAVE_SUBJECT_ERR'), body: this.$t('GAME_SAVE_BODY_ERR'), variant: 'warning' };
        this.openModal({ message, callback: this.closeModal });
      } else {
        const data = { username: this.user.username, game: this.game, language: this.$i18n.locale };
        this.save({ data });
      }
    },
  },
};
</script>
