import fetch from 'node-fetch';
import { C } from '@/constants/constants';

async function handleResponse(response) {
  const r = await response.json();
  if (response.ok) return r;
  return Promise.reject(r);
}

function login(data) {
  const { username, password, language } = data;
  const payload = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password, language }),
  };
  return fetch(`${C.SERVER_URL}/user/login`, payload)
    .then(handleResponse);
}

function register(data) {
  const payload = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${C.SERVER_URL}/user/register`, payload)
    .then(handleResponse);
}
function update(data) {
  const payload = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${C.SERVER_URL}/user/update`, payload)
    .then(handleResponse);
}
function remove(data) {
  const payload = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${C.SERVER_URL}/user/delete`, payload)
    .then(handleResponse);
}
const userServices = {
  login,
  register,
  update,
  remove,
};

export default userServices;
