<template>
  <b-form-group id="input-group-3" :label="$t('EMAIL')" label-for="input-3">
    <b-input-group class="mb-2">
      <b-input-group-prepend is-text><b-icon icon="envelope-fill" /></b-input-group-prepend>
      <b-form-input
        id="input-email"
        :value="email"
        type="email"
        name="email"
        :formatter="formatter"
        required
        :placeholder="$t('EMAIL_PLACEHOLDER')"
        @keyup="$emit('setEmail', $event.target.value)"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Email',
  props: {
    email: String,
  },
  // data: () => ({ email: '' }),
  // computed: {
  //   ...mapState('user', ['user', 'message', 'status', 'callback']),
  // },
  // mounted() {
  //   this.email = this.user.email;
  // },
  methods: {
    formatter(value) {
      return value.toLowerCase();
    },
  },
};
</script>
