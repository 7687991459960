<template>
  <b-form-group id="input-group-4" :label="$t('AGE')" label-for="input-4">
    <b-input-group class="mb-2">
      <b-input-group-prepend is-text>
        <b-icon icon="calendar2-date" />
      </b-input-group-prepend>
      <b-form-input
        id="input-age"
        :value="age"
        type="number"
        name="age"
        min="18"
        max="120"
        required
        :placeholder="$t('AGE_PLACEHOLDER')"
        :number="true"
        @keyup="$emit('setAge', $event.target.value)"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Age',
  props: {
    age: [Number, String],
  },
  computed: {
    ...mapState('user', ['user', 'message', 'status', 'callback']),
  },
};
</script>
