<template>
  <div class="game container center">
    <h2 class="text-info">{{ $t('GAME_OPERATION') }}</h2>
    <p>{{ $t('GAME_OPERATION_P1') }}</p>
    <p>{{ $t('GAME_OPERATION_P2') }}</p>
    <div class="game container center">
      <b-carousel id="carousel-fade" v-model="slide" :interval="6000" controls no-animation indicators center background="#a2d7df" img-width="1024" img-height="480" class="shadow p-3 mb-5 bg-white rounded" style="text-shadow: 3px 3px 4px #fff;" align="center">
        <b-carousel-slide :caption="$t('FIRST_STEP')" img-blank center>
          <p class="mt-4">{{ $t('FIRST_STEP_P') }}</p>
          <b-img class="shadow-lg" center rounded :src="require('../../../public/images/Step1.png')" alt="Step 1" width="454" height="300" ></b-img>
        </b-carousel-slide>
        <b-carousel-slide :caption="$t('SECOND_STEP')" img-blank>
          <p class="mt-4">{{ $t('SECOND_STEP_P') }}</p>
          <b-img class="shadow-lg " center rounded :src="require('../../../public/images/Step2.png')" alt="Step 1" width="454" height="220" ></b-img>
        </b-carousel-slide>
        <b-carousel-slide :caption="$t('THIRD_STEP')" img-blank>
          <p class="mt-4">{{ $t('THIRD_STEP_P') }}</p>
          <b-img class="shadow-lg " center rounded :src="require('../../../public/images/Step3.png')" alt="Step 1" width="454" height="220" ></b-img>
        </b-carousel-slide>
        <b-carousel-slide :caption="$t('FOURTH_STEP')" img-blank>
          <p class="mt-4">{{ $t('FOURTH_STEP_P') }}</p>
          <b-img class="shadow-lg " center rounded :src="require('../../../public/images/Step4.png')" alt="Step 1" width="750" height="320" ></b-img>
        </b-carousel-slide>
        <b-carousel-slide :caption="$t('FINAL_STEP')" img-blank>
          <p class="mt-4">{{ $t('FINAL_STEP_P') }}</p>
          <b-img class="shadow-lg " center rounded :src="require('../../../public/images/Completed.png')" alt="Step 1" width="754" height="180" ></b-img>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <b-alert class="mt-4" variant="danger" show>
      <span class="font-weight-bold">{{ $t('RULES') }} </span>
      <b-icon icon="arrow-right-short"/>
      <span>{{ $t('RULES_P') }}</span>
    </b-alert>
    <h4 class="text-info">{{ $t('WORD_TO_BE_DEFINED') }}</h4>
    <p>{{ $t('WORD_TO_BE_DEFINED_P1') }}</p>
    <p>{{ $t('WORD_TO_BE_DEFINED_P2') }}</p>
    <p>{{ $t('EXCEPTIONS') }}</p>
    <ul class="fa-ul text-info">
      <li><span class="text-dark">{{ $t('EXCEPTIONS_P1') }}</span></li>
      <li><span class="text-dark">{{ $t('EXCEPTIONS_P2') }}</span></li>
      <li><span class="text-dark">{{ $t('EXCEPTIONS_P3') }}</span></li>
    </ul>
    <p>{{ $t('EXCEPTIONS_EXEMPLE') }}</p>
    <b-alert show><span class="font-weight-bold">{{ $t('TIP') }} <b-icon icon="arrow-right-short"></b-icon></span> {{ $t('TIP_P1') }}</b-alert>
    <p>{{ $t('TIP_P2') }}</p>
  </div>
</template>

<script>
export default {
  name: 'Instructions',
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
};
</script>

<style>
.carousel-caption h3 {
  color: #111;
}
.carousel-caption p {
  color: #111;
}
.carousel-caption {
  top: 20px;
}
</style>
