import gameServices from './services/game.services';

const state = {
  _id: { $oid: undefined },
  history: [],
  hidx: 0,
  root_symbol: '',
  stop_symbols: [],
  state: {
    symbol: '',
    definition: '',
    entries: [],
    to_define: [],
    symbol2index: {},
    status: 'empty',
    message: undefined,
    svg: '',
  },
};

const empty = {
  _id: { $oid: undefined },
  history: [],
  hidx: 0,
  root_symbol: '',
  stop_symbols: [],
  state: {
    symbol: '',
    definition: '',
    entries: [],
    to_define: [],
    symbol2index: {},
    status: 'empty',
    message: undefined,
    svg: '',
  },
};

const actions = {
  reset({ commit }) {
    commit('set', { game: empty });
  },
  async start({ commit }, { startSymbol, language }) {
    const game = await gameServices.start(startSymbol, language);
    commit('set', { game });
  },
  addDefinition({ commit }, { data }) {
    gameServices.addDefinition(data)
      .then((g) => {
        commit('addDefinition', g);
      })
      .catch((err) => {
        const callback = () => commit('user/closeModal', undefined, { root: true });
        const { message } = err;
        commit('setStatus', 'error');
        commit('user/openModal', { message, callback }, { root: true });
      });
  },
  removeDefinition({ commit }, { data }) {
    gameServices.removeDefinition(data)
      .then((g) => {
        commit('addDefinition', g);
      })
      .catch((err) => {
        const callback = () => commit('user/closeModal', undefined, { root: true });
        const { message } = err;
        commit('setStatus', 'error');
        commit('user/openModal', { message, callback }, { root: true });
      });
  },
  setSymbol({ commit }, { symbol }) {
    commit('setSymbol', { symbol });
  },
  setDefinition({ commit }, { definition }) {
    commit('setDefinition', { definition });
  },
  async addStopSymbol({ commit }, { stopSymbol, game }) {
    const g = await gameServices.addStopSymbol(stopSymbol, game);
    commit('updateStopSymbol', g);
  },
  async removeStopSymbol({ commit }, { stopSymbol, game }) {
    const g = await gameServices.removeStopSymbol(stopSymbol, game);
    commit('updateStopSymbol', g);
  },
  undo({ commit }, { game }) {
    const hidx = game.hidx > 0 ? game.hidx - 1 : 0;
    commit('undo', { game, hidx });
  },
  redo({ commit }, { game }) {
    const hidx = game.hidx < game.history.length - 1 ? game.hidx + 1 : game.hidx;
    commit('redo', { game, hidx });
  },
  shuffle({ commit }, { game }) {
    const to_define = game.state.to_define.sort(() => Math.random() - 0.5);
    commit('shuffle', { to_define });
  },
  save({ commit }, { data }) {
    const callback = () => commit('user/closeModal', undefined, { root: true });
    gameServices.save(data).then(({ game, message }) => {
      commit('set', { game });
      commit('user/openModal', { message, callback }, { root: true });
    }).catch((err) => {
      commit('user/openModal', { message: err.message, callback }, { root: true });
    });
  },
  load({ commit }, { data }) {
    const callback = () => commit('user/closeModal', undefined, { root: true });
    gameServices.load(data).then(({ game }) => {
      commit('set', { game });
    }).catch((err) => {
      commit('user/openModal', { message: err.message, callback }, { root: true });
    });
  },
  loadMany({ commit }, { username }) {
    gameServices.loadMany({ username }).then(({ games, message }) => {
      commit('user/loadGames', { games }, { root: true });
    }).catch((err) => {
      const callback = () => commit('user/closeModal', undefined, { root: true });
      commit('user/openModal', { message: err.message, callback }, { root: true });
    });
  },
  remove({ commit }, { data }) {
    if (!data.id) {
      commit('set', { game: empty });
    } else {
      gameServices.remove(data).then(({ games, message }) => {
        if (games) {
          commit('user/loadGames', { games }, { root: true });
        }
        commit('set', { game: empty });
      }).catch((err) => {
        const callback = () => commit('user/closeModal', undefined, { root: true });
        commit('user/openModal', { message: err.message, callback }, { root: true });
      });
    }
  },
};

const mutations = {
  set(state, { game }) {
    state._id = game._id ? game._id.$oid : undefined;
    state.root_symbol = game.root_symbol;
    state.stop_symbols = game.stop_symbols;
    state.state = game.state;
    state.history = game.history;
    state.hidx = game.hidx;
  },
  addDefinition(state, game) {
    state.history = game.history;
    state.hidx = game.hidx;
    state.state.symbol = '';
    state.state.definition = '';
    state.state.symbol2index = game.state.symbol2index;
    state.state.entries = game.state.entries;
    state.state.to_define = game.state.to_define;
    state.state.status = game.state.status;
    state.state.message = game.state.message;
    state.state.svg = game.state.svg;
  },
  setSymbol(state, { symbol }) {
    state.state.symbol = symbol;
  },
  setDefinition(state, { definition }) {
    state.state.definition = definition;
  },
  setStatus(state, status) {
    state.state.status = status;
  },
  updateStopSymbol(state, game) {
    state.stop_symbols = game.stop_symbols;
  },
  undo(state, { game, hidx }) {
    state.state = game.history[hidx];
    state.hidx = hidx;
  },
  redo(state, { game, hidx }) {
    state.state = game.history[hidx];
    state.hidx = hidx;
  },
  shuffle(state, { to_define }) {
    state.state.to_define = to_define;
  },
  draw(state, svg) {
    state.state.svg = svg;
  },
};

export const game = {
  namespaced: true,
  state,
  actions,
  mutations,
};
