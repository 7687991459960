import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import vueCountryRegionSelect from 'vue-country-region-select';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import { store } from './store';
import { router } from './router';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css';
import enData from './constants/translations/en.json';
import frData from './constants/translations/fr.json';

const messages = { en: enData, fr: frData };

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en',
  messages,
});

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(vueCountryRegionSelect);

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount('#app');
