<template>
  <div id="input-group-5">
    <p>{{ $t('KNOWN_LANGUAGES') }}:</p>
    <b-form-group v-for="(language, counter) in model" :key="counter" class="language">
      <label for="duration">{{ counter + 1 }}. {{ $t('LANGUAGE') }}:</label>
      <b-icon icon="x-square-fill" class="float-right" type="button" variant="danger" @click="deleteLanguage(counter)">X</b-icon>
      <b-input-group class="mb-2">
        <b-form-select
          id="input-name-language"
          v-model.lazy="language.code"
          list="input-list"
          type="String"
          required
          for="l"
          class="text-secondary"
          style="width: 100%"
        >
          <b-form-select-option v-for="l in datosLanguages" :key="l.value" :value="l.value"> {{ l.text }} </b-form-select-option>
        </b-form-select>
      </b-input-group>
      <b-input-group class="mb-2">
        <b-form-select
          id="input-knowledge-language"
          v-model.lazy="language.knowledge_language"
          list="input-list"
          type="String"
          name="knowledge_language"
          :options="options"
          class="text-secondary"
          required
        />
      </b-input-group>
      <b-input-group class="mb-2">
        <b-form-input
          id="input-age-language"
          v-model.lazy="language.knowledge_age"
          type="number"
          name="knowledge_age"
          min="0"
          max="120"
          required
          :placeholder="$t('AGE_LANGUAGE_PLACEHOLDER')"
          :number="true"
        />
      </b-input-group>
    </b-form-group>
    <b-button class="float-right" type="button" variant="outline-secondary" @click="addLanguage">{{ $t('ADD_LANGUAGE') }}</b-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import datosLanguages from '../../../constants/languages.json';

export default {
  name: 'Languages',
  props: {
    languages: Array,
  },
  data() {
    return {
      options: [
        { value: null, text: this.$t('KNOWLEDGE_LANGUAGE_PLACEHOLDER') },
        { value: 'Advanced', text: this.$t('ADVANCED') },
        { value: 'Intermediary', text: this.$t('INTERMEDIARY') },
        { value: 'Elementary', text: this.$t('ELEMENTARY') },
        { value: 'Mother tongue', text: this.$t('MOTHER_TONGUE') },
      ],
    };
  },
  computed: {
    ...mapState('user', ['user', 'message', 'status', 'callback']),
    datosLanguages: () => datosLanguages,
    model: {
      get() {
        return this.languages;
      },
      set(model) {
        this.$emit('setLanguages', model);
      },
    },
  },
  methods: {
    addLanguage() {
      const ls = this.languages;
      ls.push({
        code: null,
        knowledge_language: null,
        knowledge_age: null,
      });
      this.$emit('setLanguages', ls);
    },
    deleteLanguage(counter) {
      const ls = this.languages;
      ls.splice(counter, 1);
      this.$emit('setLanguages', ls);
    },
  },
};
</script>
