<template>
  <div class="game container center">
    <Modal v-if="message" :src="require('../../../public/images/board-4874863_960_720.jpg')" :message="message" buttonText="OK" :callback="callback" />
    <Load v-if="load && games.length" :games="games" :username="user.username" @setLoad="setLoad($event)" />
    <Start v-else-if="game.state.status === 'empty'" @setLoad="setLoad($event)" />
    <Play v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Start from './Start.vue';
import Play from './Play.vue';
import Modal from '../Modal.vue';
import Load from './Load.vue';

export default {
  name: 'Game',
  components: {
    Start,
    Play,
    Modal,
    Load,
  },
  data: () => ({ load: false }),
  computed: {
    ...mapState(['game']),
    ...mapState('user', ['user', 'games', 'message', 'status', 'callback']),
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
    setLoad(value) {
      this.load = value;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
</style>
