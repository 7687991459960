<template>
  <div id="home">
    <br>
    <div class="container center">
      <b-jumbotron bg-variant="Secondary" text-variant="dark" border-variant="info">
        <template #header><h1> {{ $t('GREETINGS') }}</h1></template>
        <template #lead>{{ $t('INFO') }}</template>
        <hr class="my-4">
        <p>{{ $t('GOAL') }}</p>
      </b-jumbotron>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
};
</script>
