<template>
  <div id="app">
    <Header/>
    <body>
      <br>
      <router-view />
    </body>
  </div>
</template>

<script>
import Header from './components/Header.vue';

export default {
  name: 'App',
  components: {
    Header,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
.nav-item a {
  color: white;
  font-size:110%;
}
.text-reset {
  font-size:110%;
}
.nav-item a {
  color: white
  }
</style>
