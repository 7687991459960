import Vue from 'vue';
import Router from 'vue-router';
import Game from '../components/game/Game.vue';
import Login from '../components/user/Login.vue';
import Register from '../components/user/Register.vue';
import Home from '../components/Home.vue';
import Instructions from '../components/game/Instructions.vue';
import Profile from '../components/user/Profile.vue';
import UpdateUser from '../components/user/Update.vue';

Vue.use(Router);

export const router = new Router({
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  routes: [
    { path: '/', name: 'home', component: Home },
    { path: '/user/login', name: 'login', component: Login },
    { path: '/user/register', name: 'register', component: Register },
    { path: '/user/profile', name: 'profile', component: Profile },
    { path: '/user/update', name: 'update-user', component: UpdateUser },
    { path: '/game', name: 'game', component: Game },
    { path: '/instructions', name: 'instructions', component: Instructions },
  ],
});
