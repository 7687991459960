<template>
  <b-modal id="bv-modal" centered hide-footer :header-bg-variant="message.variant">
    <div class="d-block text-center">
      <h4><b :class="`text-${message.variant}`">{{ message.subject }} </b> {{ message.body }}</h4>
      <b-img v-bind="mainProps" center :src="src" alt="Image Login Ok" rounded="circle" />
    </div>
    <div class="float-right">
      <b-button class="mr-2" :variant="message.variant" @click="callback">{{ buttonText }}</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    src: String,
    message: Object,
    buttonText: String,
    callback: {
      type: Function,
      default: () => this.$bvModal.hide('bv-modal'),
    },
  },
  data() {
    return {
      mainProps: { width: 375, height: 280, class: 'm3' },
    };
  },
  mounted() {
    this.$bvModal.show('bv-modal');
  },
};
</script>
