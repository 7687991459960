import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { user } from './user.module';
import { game } from './game.module';

Vue.use(Vuex);

const persistedState = createPersistedState({
  paths: ['game', 'user'],
});

export const store = new Vuex.Store({
  modules: {
    user,
    game,
  },
  plugins: [persistedState],
});
