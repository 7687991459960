<template>
  <div id="header">
    <header class="header">
      <div id="title" class="container-md">
        <b-jumbotron header-level="4" style="height: 150px">
          <template #header><b-icon icon="bookmark-check-fill" /> {{ $t('TITLE') }} </template>
        </b-jumbotron>
      </div>
      <div id="menu" class="menu container center">
        <b-navbar toggleable="lg" type="dark" variant="info">
          <b-navbar-brand>
            <b-icon icon="house-fill" />
            <b-link to="/#/" class="text-white"> {{ $t('HOME') }}</b-link>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse" />
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="mx">
              <b-nav-item />
              <b-nav-item v-if="!user"><b-icon icon="person-fill" /><router-link to="/user/login"> {{ $t('LOGIN') }}</router-link> </b-nav-item>
              <b-nav-item v-if="user" @click="doLogout"><b-icon icon="person-x-fill" /><router-link to="/#/"> {{ $t('LOGOUT') }}</router-link> </b-nav-item>
              <b-nav-item v-if="!user"><b-icon icon="person-plus-fill" /><router-link to="/user/register"> {{ $t('REGISTER') }}</router-link> </b-nav-item>
              <b-nav-item><b-icon icon="play" /><router-link to="/game"> {{ $t('GAME') }}</router-link> </b-nav-item>
              <b-nav-item><b-icon icon="info-circle" /><router-link to="/instructions"> {{ $t('INSTRUCTIONS') }}</router-link> </b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto text-reset">
              <b-nav-item-dropdown text="Lang" right>
                <b-form-select v-model="$i18n.locale" :options="options" />
              </b-nav-item-dropdown>
              <b-nav-item-dropdown right>
                <template #button-content>
                  <em>{{ $t('USER') }}</em>
                </template>
                <b-dropdown-item href="#/user/profile">
                  <p class="text-dark">{{ $t('PROFILE') }}</p>
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </header>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Header',
  data() {
    return {
      options: [
        { value: 'en', text: 'EN' },
        { value: 'fr', text: 'FR', disabled: false },
      ],
    };
  },
  computed: {
    ...mapState('user', ['user', 'message', 'status', 'callback']),
  },
  methods: {
    ...mapActions('user', {
      logout: 'logout',
    }),
    ...mapActions('game', {
      resetGame: 'reset',
    }),
    doLogout() {
      this.logout();
      this.resetGame();
    },
  },
};
</script>

<style>

.nav-item a {
  color: white;
  font-size:110%;
}
.text-reset {
  font-size:110%;
}
.nav-item a {
  color: white
  }
</style>
