const SERVER_URL = 'https://combinat.info.uqam.ca/api';
// const START_SYMBOLS = {
//   en: ['apple|noun', 'car|noun', 'clock|noun'],
//   fr: ['pomme|nom', 'voiture|nom', 'horloge|nom'],
// };
const START_SYMBOLS = {
  en: ['apple', 'car', 'clock'],
  fr: ['pomme', 'voiture', 'horloge'],
};
const HOME_PAGE_INFO = 'Welcome to the dictionary game';
const SEPARATOR = '|';

export const C = {
  SERVER_URL,
  HOME_PAGE_INFO,
  START_SYMBOLS,
  SEPARATOR,
};
