<template>
  <b-card class="mt-2 shadow bg-white">
    <b-card-text class="mt-3">{{ $t('TO_DEFINED') }}: <strong class="text-primary">{{ symbol }}</strong></b-card-text>
    <b-row class="mt-2">
      <b-col sm="2">
        <label for="textarea-default">{{ $t('DEFINITION') }}:</label>
      </b-col>
      <b-col sm="10">
        <b-form-textarea id="textarea-default" :value="definition" type="text" name="definition" :placeholder="$t('DEFINITION_PLACEHOLDER')" @change="onChange($event)" />
        <b-button class="mr-1 mt-2 float-right" variant="info" type="submit" @click="doAddDefinition">{{ $t('ADD') }}</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Definition',
  props: {
    symbol: {
      type: String,
      default: '',
    },
    definition: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['game']),
  },
  methods: {
    ...mapActions('game', {
      addDefinition: 'addDefinition',
      setDefinition: 'setDefinition',
    }),
    doAddDefinition(event) {
      if (event) {
        const { symbol, definition, game } = this;
        const language = this.$i18n.locale;
        if (definition && symbol) {
          const data = { symbol, definition, game, language };
          this.addDefinition({ data });
        }
      }
    },
    onChange(definition) {
      this.setDefinition({ definition });
    },
  },
};
</script>
