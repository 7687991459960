<template>
  <div class="container center">
    <template id="login">
      <b-container class="bv-example-row" fluid="md">
        <b-col class="mx-auto app-login-box">
          <div class="modal-dialog w-100 mx-auto">
            <div class="modal-content">
              <div class="modal-body">
                <b-form @reset="onReset" @submit="onSubmit">
                  <div class="modal-header justify-content-center">
                    <h4 class="mt-2 text-center text-info"> {{ $t('ENTER_ACCOUNT') }} </h4>
                  </div>
                  <b-row class="justify-content-end ">
                    <b-link class="text-primary" :to="{ path: '/user/register'}">
                      <span>{{ $t('DONT_HAVE_ACCOUNT_YET') }}</span>
                      <br>
                      <b-icon class="float " icon="person-plus-fill" />
                      <span>{{ $t('YOU_CAN_REGISTER') }}</span>
                    </b-link>
                  </b-row>
                  <b-row>
                    <b-col>
                      <Username :username="username" :newUser="false" @setUsername="setUsername($event)" />
                      <Password :newUser="false" :connectionType="'login'" @submit.stop.prevent @setPassword="setPassword($event)" />
                    </b-col>
                  </b-row>
                  <div class="modal-footer clearfix">
                    <div class="float-right">
                      <b-button class="mr-5" type="reset" variant="secondary" @reset="onReset">{{ $t('RESET') }}</b-button>
                      <b-button v-if="!user" class="mr-1" variant="info" type="submit" @click="doLogin">{{ $t('LOGIN') }}</b-button>
                    </div>
                  </div>
                </b-form>
                <div class="divider" />
              </div>
            </div>
          </div>
        </b-col>
      </b-container>
      <Modal v-if="message && status ==='logged'" :src="require('../../../public/images/startup-2480722_1920.jpg')" :message="message" buttonText="Play" :callback="callback" />
      <Modal v-if="message && status === 'failure'" :src="require('../../../public/images/board-4874863_960_720.jpg')" :message="message" buttonText="Login" :callback="callback" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Username from './profile/Username.vue';
import Password from './profile/Password.vue';
import Modal from '../Modal.vue';

export default {
  name: 'Login',
  components: {
    Username,
    Password,
    Modal,
  },
  data() {
    return {
      username: '',
      password: '',
    };
  },
  computed: {
    ...mapState('user', ['user', 'message', 'status', 'callback']),
  },
  methods: {
    ...mapActions('user', { login: 'login' }),
    doLogin() {
      const { username, password } = this;
      if (username && password && password.length > 1) {
        const data = { username, password, language: this.$i18n.locale };
        this.login({ data });
      }
    },
    onReset(event) {
      event.preventDefault();
      this.username = '';
      this.password = '';
    },
    onSubmit(event) {
      event.preventDefault();
    },
    setUsername(value) {
      this.username = value;
    },
    setPassword(value) {
      this.password = value;
    },
  },
};
</script>
