<template>
  <div class="container center">
    <template id="login">
      <b-container class="bv-example-row" fluid="md">
        <b-col class="mx-auto app-login-box">
          <div class="modal-content">
            <div class="modal-body">
              <b-form @reset="onReset" @submit="onSubmit">
                <div class="modal-header justify-content-center">
                  <h4 class="text-info modal-title">{{ $t('CREATE_AN_ACCOUNT') }}</h4>
                </div>
                <b-row class="justify-content-end">
                  <b-link class="text-primary " :to="{ path: '/user/login'}">
                    <span>{{ $t('ALREADY_HAVE_ACCOUNT') }}</span>
                    <br>
                    <b-icon class="float " icon="person-fill" />
                    <span>{{ $t('LOGIN') }}</span>
                  </b-link>
                </b-row>
                <b-row>
                  <b-col>
                    <Username :username="username" :newUser="true" @setUsername="setProp('username', $event)" />
                    <Password :newUser="true" :connectionType="'register'" @setPassword="setProp('password', $event)" />
                    <Email :email="email" @setEmail="setProp('email', $event)" />
                    <Age :age="age" @setAge="setProp('age', $event)" />
                    <Gender :gender="gender" @setGender="setProp('gender', $event)" />
                    <PrivacyPolicy @setPolicy="setProp('policy', $event)" />
                  </b-col>
                  <b-col>
                    <EducationLevel :education_level="education_level" @setEducationLevel="setProp('education_level', $event)" />
                    <CountryRegion :country="country" :region="region" @setCountry="setProp('country', $event)" @setRegion="setProp('region', $event)" />
                    <Languages :languages="languages" @setLanguages="setProp('languages', $event)" />
                  </b-col>
                </b-row>
                <div class="modal-footer clearfix">
                  <div class="float-right">
                    <b-button class="mr-5" type="reset" variant="secondary" @reset="onReset">{{ $t('RESET') }}</b-button>
                    <b-button v-if="!user" class="mr-1" variant="info" type="submit" @click="doRegister">{{ $t('REGISTER') }}</b-button>
                  </div>
                </div>
              </b-form>
              <div class="divider" />
            </div>
          </div>
        </b-col>
      </b-container>
      <Modal v-if="message && status ==='registered'" :src="require('../../../public/images/board-4874863_960_720.jpg')" :message="message" buttonText="Play" :callback="callback" />
      <Modal v-if="message && status === 'failure'" :src="require('../../../public/images/board-4874863_960_720.jpg')" :message="message" buttonText="Register" :callback="callback" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Username from './profile/Username.vue';
import Password from './profile/Password.vue';
import Email from './profile/Email.vue';
import Age from './profile/Age.vue';
import EducationLevel from './profile/EducationLevel.vue';
import Gender from './profile/Gender.vue';
import CountryRegion from './profile/CountryRegion.vue';
import Languages from './profile/Languages.vue';
import PrivacyPolicy from './profile/PrivacyPolicy.vue';
import Modal from '../Modal.vue';

export default {
  name: 'Register',
  components: {
    Username,
    Password,
    Email,
    Age,
    EducationLevel,
    Gender,
    CountryRegion,
    Languages,
    PrivacyPolicy,
    Modal,
  },
  data() {
    return {
      username: '',
      password: '',
      email: '',
      age: '',
      languages: [{ code: null, knowledge_language: null, knowledge_age: undefined }],
      country: '',
      region: '',
      gender: '',
      education_level: '',
      policy: false,
    };
  },
  computed: {
    ...mapState('user', ['user', 'message', 'status', 'callback']),
  },
  methods: {
    ...mapActions('user', { register: 'register' }),
    validate() {
      const { username, password, email, age, languages, country, region, education_level, gender, policy } = this;
      const validateLanguage = Object.entries(languages).every(([k, v]) => (v.code && v.knowledge_language && v.knowledge_age));
      return !!(username && password && password.length > 1 && email && validateLanguage && age && languages && country && region && education_level && gender && policy);
    },
    doRegister() {
      if (this.validate()) {
        const { username, password, email, age, languages, country, region, education_level, gender } = this;
        const language = this.$i18n.locale;
        const data = { username, password, email, age, languages, country, region, education_level, gender, language };
        this.register({ data });
      }
    },
    onReset(event) {
      event.preventDefault();
      this.username = '';
      this.password = '';
      this.email = '';
      this.age = '';
      this.country = '';
      this.region = '';
      this.gender = '';
      this.education_level = '';
      this.languages = [{ code: null, knowledge_language: null, knowledge_age: undefined }];
      this.policy = false;
    },
    onSubmit(event) {
      event.preventDefault();
    },
    setProp(propname, value) {
      this[propname] = value;
    },
  },
};
</script>

<style>
.action {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
