<template>
  <b-modal id="bv-modal-load" centered hide-footer header-bg-variant="info">
    <div class="mt-3">
      <b-list-group>
        <b-list-group-item v-for="(game, index) in games" :id="game._id.$oid" :key="index">
          <div class="flex justify-content">
            <b>{{ game.root_symbol }}</b>
            <div class="float-right">
              <b-button class="mr-2" variant="outline-info" @click="doRemove(game)">{{ $t('DELETE') }}</b-button>
              <b-button class="mr-2" variant="outline-info" @click="doLoad(game)">{{ $t('LOAD') }}</b-button>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
      <br>
      <div class="float-right">
        <b-button class="mr-2" variant="outline-info" @click="$emit('setLoad', false)">{{ $t('OK') }}</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Load',
  props: {
    games: Array,
    username: String,
  },
  data: () => ({
    mainProps: { width: 375, height: 280, class: 'm3' },
  }),
  mounted() {
    this.$bvModal.show('bv-modal-load');
  },
  methods: {
    ...mapActions('game', { load: 'load', remove: 'remove' }),
    doRemove(game) {
      const data = { username: this.username, id: game._id.$oid, language: this.$i18n.locale };
      this.remove({ data });
    },
    doLoad(game) {
      const data = { id: game._id.$oid, language: this.$i18n.locale };
      this.load({ data });
      this.$emit('setLoad', false);
    },
  },
};
</script>
