<template>
  <div class="mt-3">
    <b-card class="mt-2 shadow bg-white">
      <h3>
        {{ $t('GAME_STATUS') }}:
        <span class="text-info">
          <b-icon v-if="game.state.status === 'over' || game.state.to_define.length === 0" icon="star-fill" animation="fade" font-scale="1" variant="info" />
          <b-icon v-if="game.state.status === 'over' || game.state.to_define.length === 0" icon="star-fill" animation="fade" font-scale="1" variant="info" />
          <b-icon v-if="game.state.status === 'over' || game.state.to_define.length === 0" icon="star-fill" animation="fade" font-scale="1" variant="info" />
          <strong>   {{ game.state.status === 'over' || game.state.to_define.length === 0 ? $t('FINISHED') : $t('UNFINISHED') }}</strong>
        </span>
      </h3>
      <span>{{ $t('WORDS_USED_IN_DICTIONARY') }} : </span>
      <b-progress v-if="game.state.entries.length > 0" height="30px" class="mt-2 " :max="game.state.entries.length + game.state.to_define.length" show-value>
        <b-progress-bar :value="game.state.entries.length" variant="success">
          <span>{{ $t('DEFINED') }}: <strong>{{ game.state.entries.length }}</strong></span>
        </b-progress-bar>
        <b-progress-bar :value="game.state.to_define.length" variant="danger">
          <span>{{ $t('NOT_DEFINED') }}: <strong>{{ game.state.to_define.length }}</strong></span>
        </b-progress-bar>
        <!--      </b-progress>
          <span>{{ $t('DICTIONARY_QUALITY') }} : {{ quality_name }} </span>-->
        <!--      <b-progress v-if="game.state.entries.length" height="30px" class="mt-2 ">-->
        <!--        <b-progress-bar :value="quality" :max="100">-->
        <!--          <span>{{ $t('AVG_RANK') }}: <strong>{{ average_rank }}</strong></span>
        </b-progress-bar>-->
      </b-progress>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Status',
  computed: {
    ...mapState(['game']),
    quality() {
      if (this.$i18n.locale === 'fr') return 0;
      const mu = this.average_rank;
      if (mu < 5) return 100;
      if (mu < 10) return 90;
      if (mu < 50) return 80;
      if (mu < 100) return 70;
      if (mu < 200) return 60;
      if (mu < 400) return 50;
      return 30;
    },
    average_rank() {
      const { entries } = this.game.state;
      const f = (acc, next) => acc + next.gpt_rank + 1;
      const r = entries.reduce(f, 0) / entries.length;
      return r.toFixed(2);
    },
    quality_name() {
      if (this.$i18n.locale === 'fr') return this.$t('N/A');
      const mu = this.average_rank;
      if (mu < 5) return this.$t('PERFECT');
      if (mu < 10) return this.$t('EXCELLENT');
      if (mu < 50) return this.$t('VERY_GOOD');
      if (mu < 100) return this.$t('GOOD');
      if (mu < 200) return this.$t('POOR');
      if (mu < 400) return this.$t('VERY_POOR');
      return this.$t('INADEQUATE');
    },
  },
};
</script>
